import React, { useState, useEffect, useRef } from 'react'
import ReactDOMServer from 'react-dom/server';
import { GoogleMap } from '@react-google-maps/api'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from "react-router"
import VizSensor from 'react-visibility-sensor'
import queryString from 'query-string'
import GoogleMapLoader from 'react-google-maps-loader'
import { useHistory } from 'react-router-dom'

//import elements
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Button from '@material-ui/core/Button'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

// import child components
import LanguageSelectionField from './searchOptions/LanguageSelectionField'
import ClinicalSelectionField from './searchOptions/ClinicalSelectionField'
import SearchSpecialistField from './searchOptions/SearchSpecialistField'
import SortingSelectionField from './searchOptions/SortingSelectionField'
import DistanceSliderSelectionField from './searchOptions/DistanceSliderSelectionField'
import PracticeDisplayOption from './searchResult/searchOptions/PracticeDisplayOption'
import PracticeDisplayLayoutOption from './searchResult/searchOptions/PracticeDisplayLayoutOption'
import PracticeSkeleton from './searchResult/PracticeSkeleton'
import GeneralInfo from './searchResult/practiceDetails/GeneralInfo'
import ContactInfo from './searchResult/practiceDetails/ContactInfo'
import PracticeFullDetails from './searchResult/PracticeFullDetails'
import Pagination from './searchResult/Pagination'

// import map elements
// import {googleMapStyles} from './searchResult/mapElements/GoogleMapStyles'
import RelatedMarkers from './searchResult/mapElements/RelatedMarkers'
import MainMarkers from './searchResult/mapElements/MainMarkers'
// icons
import CloseIcon from '@material-ui/icons/Close'
import { ReactComponent as InfoIcon} from './INFO_ICON.svg'
// redux action
import { practiceSearch } from '../../redux/actions/searchPracticeAction'
import { setResultsHidden } from '../../redux/actions/searchPracticeAction'

// style sheet
import './SearchResult.sass'


const AdhocBanner = () => {
    const [searchResultsDesktop, setsearchResultsDesktop] = useState('');
    const [searchResultsMobile, setsearchResultsMobile] = useState('');
    const [clickThru, setClickThru] = useState('#');
    const [clickTarget, setClickTarget] = useState('_self');
  
    useEffect(() => {
      fetch('/adhoc_banner/adhoc_banner_search.json')
        .then(response => response.json())
        .then(data => {
          setsearchResultsDesktop(data.searchResultsDesktop);
          setsearchResultsMobile(data.searchResultsMobile);
          setClickThru(data.clickThru);
          setClickTarget(data.clickTarget);
        })
        .catch(err => console.log(err));
    }, []);
    
    if( searchResultsDesktop==="" || searchResultsMobile==="" ){
        return <></>;
    }

    return (
      <a href={clickThru} target={clickTarget} class="adhoc-banner-link adhoc-search-results">
        <img src={searchResultsMobile} alt="Banner" class="adhoc-banner-image adhoc-banner-mobile" />
        <img src={searchResultsDesktop} alt="Banner" class="adhoc-banner-image adhoc-banner-desktop" />
      </a>
    );
}

export default function SearchResult(props) {
    // GET SEARCH QUERIES FROM URL
    const location = useLocation()
    let history = useHistory()
    const inputQueries = queryString.parse(location.search)
          // INITIAL STATE
    // map state
    const [mapRef, setMapRef] = useState(null)
    const [currentCoords, setCurrentCoords] = useState([])
    const [boundCoords, setBoundCoords] = useState([])
    const [selectedPractice, setSelectedPractice] = useState({
        isSelected: false,
        selectedId: 0,
        selectedPractice: []
    })
    const [isMapLoaded, setIsMapLoaded] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [pagination, setPagination] = useState([])
    // component state
    const dispatch = useDispatch()
    const [isDataLoaded, setIsDataLoaded] = useState(false)
    // practice grid display style
    const [isGrid, setIsGrid] = useState(false)
    // if header is visible - handle sticky shadow
    const [isHeaderVisible, setIsHeaderVisible] = useState(false) 

    const [isMapScriptRequired, setIsMapScriptRequired] = useState(false)
    const google_api_key = "AIzaSyBUXY9mb7uoQp8PtmLH8tNkLvr7Vdm6xAQ"

    // -------------------------END INITIAL STATE
   
    // search query
    const [searchQuery, setSearchQuery] = useState({
        practiceName: inputQueries.practice,
        userName: inputQueries.physio,
        state: '',
        SuburbPost: inputQueries.SuburbPost,
        language: inputQueries.language,
        Clinical: inputQueries.clinical,
        distance: inputQueries.distance === "" ? 5 : inputQueries.distance,
        stateComp: '',
        suburbComp: '',
        postcodeComp: '',
        UserLocationHere: '',
        lat: '',
        lng: '',
        latCurrent: inputQueries.lat,         
        lngCurrent: inputQueries.lng,
        NumItem: 6,
        page: inputQueries.page === 0 || undefined ? 1 : inputQueries.page,
        CurrentLocation: inputQueries.isCurrentLocation,
        ASCDESC: inputQueries.sort === "" || "undefined"? "distance" : inputQueries.sort,
        NDIS: inputQueries.NDIS,
        Telehealth: inputQueries.Telehealth,
        searchSpecialist: inputQueries.searchSpecialist
       
    })
   
    useEffect(() => {
        // search query variables
        const practice = searchQuery.practiceName
        const physio = searchQuery.userName
        const SuburbPost = inputQueries.SuburbPost
        const distance = searchQuery.distance
        const language = searchQuery.language
        const clinical = searchQuery.Clinical
        const lat = searchQuery.latCurrent
        const lng = searchQuery.lngCurrent
        const page = searchQuery.page
        const sort = searchQuery.ASCDESC === ""? "distance" : searchQuery.ASCDESC
        const NDIS = searchQuery.NDIS 
        const Telehealth = searchQuery.Telehealth 
        const searchSpecialist = searchQuery.searchSpecialist 
        let query = `?practice=${practice}&physio=${physio}&SuburbPost=${SuburbPost}&clinical=${clinical}&distance=${distance}&language=${language}&lat=${lat}&lng=${lng}&page=${page}&sort=${sort}&NDIS=${NDIS}&Telehealth=${Telehealth}&searchSpecialist=${searchSpecialist}&mapScriptRequired=true`
        // push query to URL
        history.push(query)
    }, [searchQuery])
    console.log(searchQuery)
    // if redirected directly from search option component, load in map script
    useEffect(() => {
        if( !isMapScriptRequired && inputQueries.mapScriptRequired){
            setIsMapScriptRequired(true)
        }
    }, [isMapScriptRequired, inputQueries.mapScriptRequired])

    // run when search query (search options) are changed
    useEffect(() => {
        // empty markers before getting new ones
        setIsLoading(true)
        setCurrentCoords([])
        setBoundCoords([])
        // request practice data
        dispatch(practiceSearch(searchQuery))
        .then(response => {
            // pass on data to set new markers
            createPlaces(response)
            getPaginations(response[0])
           
        })
        .catch(error => {
            console.log(error)
        })
    }, [dispatch, searchQuery])
    
    // if practice is requested directly from hash url
    useEffect(() => {
        inputQueries.PracticeID &&
            setSelectedPractice({
                ...selectedPractice,
                isSelected: true,
                selectedId: inputQueries.PracticeID
            })
    }, [selectedPractice, inputQueries.PracticeID])

    // retrieve search result for all practices
    const allPractices = useSelector(state => state.searchPractice.searchResults)
    console.log(searchQuery);
    console.log(allPractices);
    // setup paginations
    const getPaginations = async (totalResult) => {
        const totalPages = Math.ceil(totalResult/searchQuery.NumItem)
        let page = 1
        const tempRange = []
        while (page <= totalPages) {
            tempRange.push({id: page})
            page += 1
        }
        await setPagination(tempRange)
    }

    const handlePagination = (pageId) => {
        setSearchQuery({
            ...searchQuery,
            page: pageId
        })
    }
    const handleClosePopUp =()=>{
        setIsClicked(false)
    }
    // MARKER HANDLERS
    // loaded markers
    const createPlaces = async (data) => {
        data.result.map(place => {
            const location = {
                lat: parseFloat(place.Lat), 
                lng: parseFloat(place.Lon)
            }
            // set new markers
            setCurrentCoords(currentCoords => [...currentCoords, location])
            return true
        })
        // render when data is fully loaded
        await setIsDataLoaded(true) 
        await setIsLoading(false)
    }

    // when a marker is selected
    const handleMarkerClick = async(id) => {
        // get practice info from loaded practices
        const practice = allPractices.resultAll.find(x => x.PracticeID === id)
        const array = []
        array.push(practice)
        // set selected practice
        await setSelectedPractice({
            ...selectedPractice,
            isSelected: true,
            selectedId: id,
            selectedPractice: array
        })
        const lat = parseFloat(practice.Lat)
        const lng = parseFloat(practice.Lon)
        // center map to selected practice
        fitBoundsSelected(lat, lng)
    }

    // when a marker is de-select
    const handleClosePracticeInfo = () => {
        // set selected practice to initial state
        setSelectedPractice({
            isSelected: false,
            selectedId: 0,
            selectedPractice: []
        })
        // reset zoom level
        mapRef.setZoom(14)
        // reset map bound to fit all markers
        fitBounds(mapRef)
    }

    // useEffect(() => {
    //      // when all coordinations are fully loaded
    //     if( currentCoords.length === searchQuery.NumItem ){
    //         console.log('%c Updated Coords', 'padding: 5px; background: green; color: #fff')
    //     }
    // }, [currentCoords, searchQuery.NumItem])

    // -----------------------------------------END MARKERS HANDLERS

    // DISTANCE HANDLERS
    // update search query on distance change
    const handleDistanceChange = (value) => {
        setSearchQuery({
            ...searchQuery,
            distance: value
        })
    }
    // -------------------------------END DISTANCE HANDLERS

    // LANGUAGE HANDLERS
    // set language element to state
    const LanguageRef = useRef(0)
    // update search query on language change

    const handleSelectedLanguage = (value) => {
        setSearchQuery({
            ...searchQuery,
            language: value
        })
    }

    // ------------------------------------END LANGUAGE HANDLERS

    // CLINICAL HANDLERS
    // set Clinical element to state
    const ClinicalAreaRef = useRef(0)

    // update search query on Clinical change
    const handleSelectedClinicalArea = (value) => {
        setSearchQuery({
            ...searchQuery,
            Clinical: value
        })
    }
    // ------------------------------------END CLINICAL HANDLERS
    // Specialist HANDLERS
    // set specialist element to state
    const SpecialistAreaRef = useRef(0)

    // update search query on Clinical change
    const handleSelectedSpecialistArea = (value) => {
        setSearchQuery({
            ...searchQuery,
            searchSpecialist: value
        })
    }
    const [isClicked, setIsClicked] = useState(false)
    const popUPInfo=()=>{
        setIsClicked(true)
    }
    // ------------------------------------END CLINICAL HANDLERS
    // SORT HANDLERS
    // set sort element to state
    const SortByRef = useRef(0)

    // update search query on sort change
    const handleSortByChange = (value) => {
        if( value === "distance" ){
            setSearchQuery({
                ...searchQuery,
                ASCDESC: "distance"
            })
        } else {
            setSearchQuery({
                ...searchQuery,
                ASCDESC: value
            })
        }
        
    }
    // ------------------------------------END SORT HANDLERS

    // DISPLAY HANDLERS
    // set display element to state
    const DisplayRef = useRef(0)

    // update search query on display change
    const handleDisplayChange = (value) => {
        setSearchQuery({
            ...searchQuery,
            NumItem: value
        })
    }
    // --------------------------------------END DISPLAY HANDLERS                     

    // fit all markers to map bound
    const fitBounds = map => {
        const bounds = new window.google.maps.LatLngBounds()
        currentCoords.map(coord => {
            bounds.extend(coord)
            return coord
        })
        map.fitBounds(bounds)
    }
    
    // center to current marker
    const fitBoundsSelected = async(lat, lng) => {
        const coord = {
            lat: lat,
            lng: lng
        }
        // smoothly move to selected coord
        mapRef.panTo(coord)
        if( mapRef.getZoom() !== 16 ){
            mapRef.setZoom(16)
        }
    }

    // initial map state on map load
    const loadHandler = map => {
        // Store a reference to the google map instance in state
        if( map ){
            if( !isMapLoaded ){
                setMapRef(map)
                fitBounds(map)
            }
            // Fit map bounds to contain all markers
            setIsMapLoaded(true)
        }
    }

    // extends google map bound to fit all coords on marker load
    const extentBoundsHandler = async (pos) => {
        boundCoords.push(pos)
        const bounds = new window.google.maps.LatLngBounds()
        await boundCoords.map(coord => {
            bounds.extend(coord)
            return coord
        })
        await mapRef.fitBounds(bounds)
    }

    // convert svg to base64 string url for map marker icon
    const encodeSvg = (reactElement) => {
        return 'data:image/svg+xml,' + escape(ReactDOMServer.renderToStaticMarkup((reactElement)));
    }

    // Filter options components
    const FilterOptionsContainer = () => {
        return (
            <div className="filter_container">
                <div className={isDataLoaded ? 'main_filters' : 'main_filters disabled'}>
                    <DistanceSliderSelectionField
                        isDataLoaded={isDataLoaded}
                        distance={searchQuery.distance}
                        handleDistanceChange={handleDistanceChange} />

                    {   (searchQuery.searchSpecialist==="" || searchQuery.searchSpecialist==="undefined") &&
                        <LanguageSelectionField 
                        languageRef={LanguageRef}
                        selectedLanguage={searchQuery.language}
                        handleSelectedLanguage={handleSelectedLanguage}
                        languages={props.languages} />}
                    {   (searchQuery.searchSpecialist==="" || searchQuery.searchSpecialist==="undefined") &&
                        <ClinicalSelectionField 
                        ClinicalAreaRef={ClinicalAreaRef}
                        selectedClinicalArea={searchQuery.Clinical}
                        handleSelectedClinicalArea={handleSelectedClinicalArea}
                        clinicalAreas={props.clinicalAreas} />
                    }
                    {   (searchQuery.searchSpecialist!=="" &&  searchQuery.searchSpecialist!=="undefined") &&
                        <SearchSpecialistField 
                            SpecialistAreaRef={SpecialistAreaRef}
                            selectedSpecialistArea={searchQuery.searchSpecialist}
                            handleSelectedSpecialistArea={handleSelectedSpecialistArea}
                            specialistAreas={props.specialistAreas} />
                    }
                </div>

                <div className={isDataLoaded ? 'extra_filters' : 'extra_filters disabled'}>
                    <SortingSelectionField 
                        sortByRef={SortByRef}
                        sortOption={searchQuery.ASCDESC}
                        handleSortByChange={handleSortByChange}
                        isDataLoaded={isDataLoaded}
                    />

                    <PracticeDisplayOption 
                        isDataLoaded={isDataLoaded}
                        DisplayRef={DisplayRef}
                        NumItem={searchQuery.NumItem}
                        handleDisplayChange={handleDisplayChange}
                    />

                    {props.screenWidth > 767 &&
                        <PracticeDisplayLayoutOption 
                            isGrid={isGrid}
                            setIsGrid={setIsGrid} />
                    }

                    <a href="/find-a-physio" style={{textDecoration: 'none'}}>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            className="back-to-search"
                            startIcon={<ChevronLeftIcon />}
                        >
                            Back to search
                        </Button>     
                    </a>
                </div>
            </div>
        )
    }

    // handle search options expand/collapse for mobile view
    const [expanded, setExpanded] = React.useState(false);
    const handleSearchOptionsExpand = panel => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false)
    }
    
    // cleanup before leaving page
    useEffect(() => {
        return () => {
            // get search option component visible
            dispatch(setResultsHidden())
            // unmount loaded data
            setIsDataLoaded(false)
        }
    }, [])

    return(
        <div className="search_main_wrapper">
            <div className="search_map">
                {isMapScriptRequired && // if mapscript is required (direct access by url with search query)
                    <GoogleMapLoader
                        params={{
                            key: google_api_key,
                            libraries: "places,geocode,geometry,drawing",
                        }}
                        render={googleMaps =>
                            googleMaps && isDataLoaded ? (
                                <React.Fragment>
                                    <GoogleMap id="map"
                                        onLoad={loadHandler}
                                        mapContainerStyle={{
                                            height: "calc(100vh - 93px)"
                                        }}
                                        // options={{styles: googleMapStyles,}} 
                                    >
                                        <MainMarkers 
                                            allPractices={allPractices}
                                            selectedPractice={selectedPractice}
                                            encodeSvg={encodeSvg}
                                            extentBoundsHandler={extentBoundsHandler}
                                            handleMarkerClick={handleMarkerClick}
                                            handleClosePracticeInfo={handleClosePracticeInfo} />

                                        <RelatedMarkers 
                                            allPractices={allPractices}
                                            selectedPractice={selectedPractice}
                                            encodeSvg={encodeSvg}
                                            handleMarkerClick={handleMarkerClick}
                                            handleClosePracticeInfo={handleClosePracticeInfo} />
                                    </GoogleMap>
                                    {isLoading &&
                                        <div className="map_loading">
                                            <span className='outer_spinner'></span>
                                            <span className='inner_spinner'></span>
                                        </div>
                                    }
                                </React.Fragment>
                            ):(
                                <div className="map_loading">
                                    <span className='outer_spinner'></span>
                                    <span className='inner_spinner'></span>
                                </div>
                            )}                    
                    />
                }
                { isDataLoaded ? (
                    <React.Fragment>
                        {!inputQueries.mapScriptRequired &&
                            <GoogleMap id="map"
                                onLoad={loadHandler}
                                mapContainerStyle={{
                                    height: "calc(100vh - 93px)"
                                }}
                                // options={{styles: googleMapStyles,}} 
                                >

                                <MainMarkers 
                                    allPractices={allPractices}
                                    selectedPractice={selectedPractice}
                                    encodeSvg={encodeSvg}
                                    extentBoundsHandler={extentBoundsHandler}
                                    handleMarkerClick={handleMarkerClick}
                                    handleClosePracticeInfo={handleClosePracticeInfo} />

                                <RelatedMarkers 
                                    allPractices={allPractices}
                                    selectedPractice={selectedPractice}
                                    encodeSvg={encodeSvg}
                                    handleMarkerClick={handleMarkerClick}
                                    handleClosePracticeInfo={handleClosePracticeInfo} />
                            </GoogleMap>
                        
                        }
                        {!inputQueries.mapScriptRequired &&
                            isLoading &&
                                <div className="map_loading">
                                    <span className='outer_spinner'></span>
                                    <span className='inner_spinner'></span>
                                </div>
                            
                        }
                    </React.Fragment>
                ) : (
                    !inputQueries.mapScriptRequired &&
                        <div className="map_loading">
                            <span className='outer_spinner'></span>
                            <span className='inner_spinner'></span>
                        </div>
                )}
                <PracticeFullDetails 
                    isSelected={selectedPractice.isSelected}
                    selectedPractice={selectedPractice.selectedPractice}
                    handleClosePracticeInfo={handleClosePracticeInfo}
                    selectedId={selectedPractice.selectedId}
                    userName={searchQuery.userName}
                    PracticeName={searchQuery.PracticeName}
                    language={searchQuery.language}
                    searchSpecialist={searchQuery.searchSpecialist}
                />
            </div>

            <div className="search_results">
                {props.screenWidth > 767 &&
                    <React.Fragment>
                        <h2 className="accent_primary_heading" >Search result</h2>
                        <VizSensor onChange={ (isVisible) => {setIsHeaderVisible(isVisible)} }>
                            <span className="result_total">Result total: {allPractices[0] === undefined ? '...' : allPractices[0]}</span>
                        </VizSensor>
                    </React.Fragment>
                }
                
                <div className={isHeaderVisible ? "results_sort_filter" : "results_sort_filter sticky"}>
                    {props.screenWidth > 767 ? (
                        <FilterOptionsContainer />
                    ) : (
                        <ExpansionPanel 
                            className="filter-options-mobile"
                            expanded={expanded === 'filter-options'} 
                            onChange={handleSearchOptionsExpand('filter-options')}
                        >
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                            >
                                <Typography>Refine search</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className="options--body">
                                <FilterOptionsContainer />
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    )}
                <AdhocBanner/>
                </div>

                {   searchQuery.searchSpecialist!=="" && searchQuery.searchSpecialist!=="undefined" &&
                    <div id="searchSpecialistInfo"><div className="spInfo"><p className="SW">S</p>Specialist Physiotherapist</div><div className="titleInfo"><div className="TW">T</div>Titled Physiotherapist</div><div id="whatinfo" onClick={popUPInfo}><div className="whatInfoIcon"><InfoIcon /></div>What do these mean</div></div>
                    
                }
                 {   searchQuery.searchSpecialist!=="" && searchQuery.searchSpecialist!=="undefined" &&
                    <div className="tipsWhatInfo">*As awarded by the Australian College of Physiotherapists</div>
                 }
               
                <div className={isGrid ? "result_grid column" : "result_grid row"}>
                    { isDataLoaded ? (
                        <React.Fragment>
                            {allPractices.result.length > 0 ? (
                                allPractices.result.map(practice => {
                                    const {PracticeID, PracticeName, distance, 
                                            Address1, Address2, City, State, Postcode, 
                                            Phone, Website, Lat, Lon,rank} = practice
                                    return(
                                        !isLoading &&
                                            <div key={PracticeID} className="practice"
                                                // onMouseEnter={() => {handlePracticeHover(PracticeID) }}
                                                // onMouseLeave={() => {handleClosePracticeInfo()}} 
                                                >
                                                <GeneralInfo 
                                                    id={PracticeID}
                                                    PracticeName={PracticeName}
                                                    distance={distance}
                                                    Address1={Address1}
                                                    Address2={Address2}
                                                    City={City}
                                                    State={State}
                                                    Postcode={Postcode}
                                                    rank = {rank}
                                                    searchSpecialist = {inputQueries.searchSpecialist}
                                                    handleMarkerClick={handleMarkerClick} />
            
                                                <ContactInfo 
                                                    Phone={Phone}
                                                    Email={practice.Email}
                                                    Website={Website}
                                                    Lat={Lat}
                                                    Lon={Lon} />
                                            </div>
                                    )
                                })
                            ) : (
                                !isLoading && <h1>No result</h1>
                            )}
                            { isLoading &&
                                <PracticeSkeleton />
                            }
                        </React.Fragment>
                    ) : (
                        <PracticeSkeleton />
                    )}
                </div>
                {   searchQuery.searchSpecialist!=="" && searchQuery.searchSpecialist!=="undefined" &&
                    <div className={isClicked?"specialistPopUp active":"specialistPopUp"}><div id="popUpSpecialisInfo"><div className="spInfo"><p className="SW">S</p>Specialist Physiotherapist</div><div className="specialistDescription">Specialisation as awarded by the Australian College of Physiotherapists is a title conferred to physiotherapists who have attained the highest level of expertise in their particular field of physiotherapy by completing a rigorous training and examination process.</div><div className="titleInfo"><p className="TW">T</p>Titled Physiotherapist</div><div className="specialistDescription">APA Titled physiotherapists are highly qualified physiotherapists with expert knowledge and skills in their area of practice. They undergo a comprehensive selection process to ensure that they achieve and maintain exceptional standards of clinical experience and knowledge.</div></div><div className="close_popup" onClick={handleClosePopUp}><CloseIcon /></div>
                    <div className="tipsFindMore">Find out more</div>
                    
                    </div>

                }
                { pagination.length > 0 &&
                    <Pagination 
                        pagination={pagination}
                        currentPage={searchQuery.page}
                        handlePagination={handlePagination}
                    />
                }

            </div>
        </div>
    )
}